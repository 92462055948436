import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { useSelector } from 'react-redux';

import Logo from '../../assets/PrismIcon.png';
import isProduction from '../../helper/IsProduction';
import { getSessionStorage } from '../../helper/SessionStorage';
import SiteSelect from '../header/site_select/SiteSelect';
import BackgroundColorSelect from './BackgroundColorSelect';
import BurgerMenu from './BurgerMenu';
import MainMenu from './MainMenu';
import UserMenu from './UserMenu';

function ResponsiveAppBar() {
  const user = useSelector((state) => state.user);
  const isProd = isProduction();
  const isAdmin = user?.userProfile?.user_right === 'Admin';
  const uatStyles = 'transition-transform group-hover:rotate-180 z-20 duration-500';
  const [backgroundColor, setBackgroundColor] = React.useState('#384451');
  const [iconBGColor, setIconBGColor] = React.useState('#384451');
  const selectedSite = useSelector((state) => state.user?.loginResponse?.selectedSite);
  const [isSiteCodeAvailable, setIsSiteCodeAvailable] = React.useState(false);

  React.useEffect(() => {
    if (selectedSite) {
      setIsSiteCodeAvailable(true);
    }
  }, [selectedSite]);

  React.useEffect(() => {
    const currentURL = window.location.href;
    if (isProd) {
      setIconBGColor('#384451');
      setBackgroundColor('#384451');
    } else if (currentURL.includes('dev.prism.jdplc.com')) {
      const lsBackground = getSessionStorage('custom-background-sidebar');
      const userBgColor = getSessionStorage('bg-user');
      const customBackground = lsBackground || '#7F1D1D';
      setIconBGColor(userBgColor);
      setBackgroundColor(customBackground);
    } else {
      const lsBackground = getSessionStorage('custom-background-sidebar');
      const userBgColor = getSessionStorage('#FFE082');
      const customBackground = lsBackground || '#b58010';
      setIconBGColor(userBgColor);
      setBackgroundColor(customBackground);
    }
  }, []);

  return (
    <AppBar position="static" sx={{ background: backgroundColor }}>
      <Container maxWidth="l">
        <Toolbar disableGutters>
          <Box className="relative flex items-center justify-center min-h-max group">
            <img className={`w-10 my-[17px] ${!isProd && isAdmin ? uatStyles : ''}`} src={Logo} alt="PRISM" />
            {!isProd && isAdmin ? <BackgroundColorSelect /> : null}
          </Box>
          <Box sx={{ ml: 2 }}>
            <SiteSelect sx={{ width: '200px' }} variant="standard" />
          </Box>
          {isSiteCodeAvailable && (
          <>
            {/* smaller screen */}
            <BurgerMenu backgroundColor={backgroundColor} iconBGColor={iconBGColor} />
            {/* bigger screen */}
            <MainMenu />
          </>
          )}
          <UserMenu backgroundColor={backgroundColor} iconBGColor={iconBGColor} />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
