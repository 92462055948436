import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';
import {
  DataGrid,
  gridClasses,
  GridToolbar,
} from '@mui/x-data-grid';
import React from 'react';
import { toast } from 'react-toastify';

import getAllUsers from '../../api/private/users_management/gets/getAllUsers';
import getUserApiAccessTypes from '../../api/private/users_management/gets/getUserApiAccessTypes';
import getUserPermissions from '../../api/private/users_management/gets/getUserPermissions';
import getUserRights from '../../api/private/users_management/gets/getUserRights';
import getUserStatuses from '../../api/private/users_management/gets/getUserStatuses';
import getUserTypes from '../../api/private/users_management/gets/getUserTypes';
import postUpdateUser from '../../api/private/users_management/posts/postUpdateUser';
import Modal from '../../components/Modal';
import NewUserModal from './modals/NewUser';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        0.2 + theme.palette.action.selectedOpacity,
      ),
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          0.2 + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
        ),
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            0.2 + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

function UserList() {
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(20);
  const [userTypes, setUserTypes] = React.useState({});
  const [userRights, setUserRights] = React.useState({});
  const [apiAccessTypes, setApiAccessTypes] = React.useState({});
  const [userStatus, setUserStatus] = React.useState({});
  const [userPermission, setUserPermission] = React.useState({});
  const [loadingTable, setLoadingTable] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const columns = [
    {
      field: 'AccountName',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'UserName',
      headerName: 'Email Address',
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'UserPermission',
      headerName: 'Permission',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'GroupName',
      headerName: 'Group',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'LastVerificationDate',
      headerName: 'Last Login',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString('en-GB', {
          day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit',
        }).replace(',', '');
      },
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <MoreVertSharpIcon
          sx={{
            cursor: 'pointer',
            '&:hover': {
              color: 'red',
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
            setOpenModal(true);
          }}
          onMouseOver={params.value}
        />
      ),
    },
  ];

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const getTableInformation = async (values) => {
    await getAllUsers()
      .then((res) => {
        const userTypeEntries = Object.entries(values[0]);
        const apiATEntries = Object.entries(values[1]);
        const userRightsEntries = Object.entries(values[2]);
        const userStatusEntries = Object.entries(values[3]);
        const userPermissionEntries = Object.entries(values[4]);

        const rowMapping = res.data.map((obj) => {
          const foundUserType = userTypeEntries.find(([, value]) => value === obj.UserType);
          const foundUserRight = userRightsEntries.find(([, value]) => value === obj.UserRight);
          const foundApiAccessType = apiATEntries.find(([, value]) => value === obj.ApiAccessType);
          const foundStatus = userStatusEntries.find(([, value]) => value === obj.Status);
          const foundUserPermission = userPermissionEntries.find(([, value]) => value === obj.UserPermission);

          return {
            ...obj,
            UserType: foundUserType ? foundUserType[0] : obj.UserType,
            UserRight: foundUserRight ? foundUserRight[0] : obj.UserRight,
            ApiAccessType: foundApiAccessType ? foundApiAccessType[0] : obj.ApiAccessType,
            Status: foundStatus ? foundStatus[0] : obj.Status,
            UserPermission: foundUserPermission ? foundUserPermission[0] : obj.UserPermission,
          };
        });
        setRows(rowMapping);
      })
      .catch((err) => {
        console.error(err);
      });
    setLoadingTable(false);
  };

  const getAllMappings = async () => {
    const ut = new Promise((resolve, reject) => {
      getUserTypes()
        .then((res) => {
          setUserTypes(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    const aat = new Promise((resolve, reject) => {
      getUserApiAccessTypes()
        .then((res) => {
          setApiAccessTypes(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    const ur = new Promise((resolve, reject) => {
      getUserRights()
        .then((res) => {
          setUserRights(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    const us = new Promise((resolve, reject) => {
      getUserStatuses()
        .then((res) => {
          setUserStatus(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    const up = new Promise((resolve, reject) => {
      getUserPermissions()
        .then((res) => {
          setUserPermission(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    Promise.all([ut, aat, ur, us, up])
      .then((values) => getTableInformation(values))
      .catch((err) => console.error(err));
  };

  React.useEffect(() => {
    getAllMappings();
  }, []);

  const processRowUpdate = React.useCallback(
    async (newRow, userRightsMapping, userTypeMapping, apiAccessTypesMapping, statusMapping, permissionMapping) => {
      const newUserRight = userRightsMapping[newRow.UserRight];
      const newUserType = userTypeMapping[newRow.UserType];
      const newApiAccessTypes = apiAccessTypesMapping[newRow.ApiAccessType];
      const newStatus = statusMapping[newRow.Status];
      const newPermission = statusMapping[newRow.permissionMapping];

      const response = await postUpdateUser(
        newRow.AccountName,
        newRow.UserName,
        newUserType,
        newUserRight,
        newRow.SiteIds,
        newStatus,
        newPermission,
        newApiAccessTypes,
        newRow.Id,
      );
      const userTypeEntries = Object.entries(userTypeMapping);
      const userRightsEntries = Object.entries(userRightsMapping);
      const apiATEntries = Object.entries(apiAccessTypesMapping);
      const userStatusEntries = Object.entries(statusMapping);
      const userPermissionEntries = Object.entries(permissionMapping);

      const responseData = response.data;

      return {
        ...responseData,
        UserType: (userTypeEntries.find(([, value]) => value === responseData.UserType)) == null
          ? responseData.UserType
          : (userTypeEntries.find(([, value]) => value === responseData.UserType))[0],
        UserRight: (userRightsEntries.find(([, value]) => value === responseData.UserRight)) == null
          ? responseData.UserRight
          : (userRightsEntries.find(([, value]) => value === responseData.UserRight))[0],
        ApiAccessType: (apiATEntries.find(([, value]) => value
          === responseData.ApiAccessType)) == null
          ? responseData.ApiAccessType
          : (apiATEntries.find(([, value]) => value === responseData.ApiAccessType))[0],
        Status: (userStatusEntries.find(([, value]) => value === responseData.Status)) == null
          ? responseData.Status
          : (userStatusEntries.find(([, value]) => value === responseData.Status))[0],
        UserPermission: (userPermissionEntries.find(([, value]) => value === responseData.UserPermission)) == null
          ? responseData.UserPermission
          : (userPermissionEntries.find(([, value]) => value === responseData.UserPermission))[0],
      };
    },
    [postUpdateUser],
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    toast.error(error);
  }, []);

  return (
    <div className="width: '100%'; margin-right: -20px;">
      <Box
        component="div"
        sx={{
          height: 'calc(100vh - 220px)', width: 'calc(100vw - 126px)', mt: -1, ml: 3.5, mr: -30,
        }}
      >
        {open && (
          <NewUserModal
            open={open}
            handleClose={() => {
              getTableInformation();
              setOpen(false);
            }}
            userTypesMapping={userTypes}
            userRightsMapping={userRights}
            apiAccessTypesMapping={apiAccessTypes}
          />
        )}
        <StripedDataGrid
          rows={rows}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          getRowId={(r) => r.Id}
          processRowUpdate={(newRow) => processRowUpdate(
            newRow,
            userRights,
            userTypes,
            apiAccessTypes,
            userStatus,
            userPermission,
          )}
          slots={{ toolbar: GridToolbar }}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          disableSelectionOnClick
          className="bg-white"
          getRowHeight={() => 'auto'}
          sx={{
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[20, 30, 40]}
          loading={loadingTable}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        />
      </Box>
      <Modal
        title="Edit User"
        open={openModal}
        onClose={handleModalClose}
        buttonLabel="Confirm"
      />
    </div>

  );
}

export default UserList;
